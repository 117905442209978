.receipts {
	padding-bottom: $footerHeight + 2rem;
}

.receipts-filter {
	margin-bottom: 20px;
	padding-left: 10px;
	position: relative;
	.receipt-filter-item {
		cursor: pointer;
		&:hover {
			text-decoration: underline;
		}

		&.active {
			color: $mainColor;
			font-weight: bold;
		}
	}

	.dropdown.custom-date {
		position: absolute;
		z-index: 1;
		height: 3.8rem;

		form {
			width: 100%;
			height: 3.8rem;
		}

		.form-row {
			display: flex;
		}

		input {
			border: 0.1rem solid $mainColor;
			border-radius: 0.4rem;
			box-shadow: none;
			box-sizing: inherit; // Forced to replace inherit values of the normalize.css
			height: 100%;
			padding: 0.6rem 1rem 0.7rem; // This vertically centers text on FF, ignored by Webkit
			width: 100%;

			&:focus {
				outline: 0;
			}
		}

		.datepicker {
			display: flex;
		}

		.button-search {
			background-color: $mainColor;
			border-radius: 5px;
			border: none;
			color: #fff;
			height: 30.6px;
			width: 30.6px;
			cursor: pointer;

			&:disabled {
				background-color: #ccc;
			}
		}
	}
}

.width-50 {
	width: 50%;
}

.filter-info {
	padding-bottom: 15px;
	padding-left: 10px;

	display: flex;
	justify-content: space-between;

	.selected-filter {
		color: $mainColor;
		text-transform: capitalize;
	}
}

.receipts-table {
	width: 100%;
	thead {
		display: none;
	}
	border-collapse: collapse;
	tr {
		cursor: pointer;
		td {
			padding: 10px;
		}
		&.selected {
			background-color: rgba(10, 132, 255, 0.09);
		}
	}
	img {
		width: 64px;
		height: 64px;

		margin-right: 20px;
	}

	.shop {
		.shop-name {
			font-weight: bold;
		}

		.issue-date {
			color: #333;
			font-size: 80%;
		}
	}

	.totalPrice {
		text-align: right;

		.total {
			display: inline-block;
			margin-top: -18px;
			color: $mainColor;
		}
	}
}

.receipt-preview {
	.receipt {
		margin: 0 auto;
		width: 295px;
		font-size: 85%;
		font-family: "Times New Roman", Times, serif;

		.company {
			text-align: center;
			margin-bottom: 15px;
		}

		.time-and-block-number {
			display: flex;
			justify-content: space-between;
		}

		table {
			width: 100%;

			.quantity,
			.price {
				text-align: right;
			}

			thead tr td {
				border-bottom: 1px dashed #333;
			}

			tfoot tr td {
				border-top: 1px dashed #333;
			}
		}

		.items {
			margin-top: 15px;
		}

		.qr img {
			margin: 0 auto;
			display: block;
		}

		.okp,
		.uid {
			font-size: 90%;
		}
	}

	&.empty {
		margin-top: 40%;
	}
}

.add-receipt-btn {
	background-color: $terciaryColor;
	i {
		display: inline-block;
		background-image: url("/scanIcon.svg");
		width: 18px;
		height: 18px;
		position: relative;
		top: 3px;
	}
}

.receipt-scanner {
	.modal-dialog {
		width: 60%;

		.modal-header {
			background-color: $mainColor;
			.modal-title {
				color: #fff;
				font-weight: bold;
				font-size: 120%;
			}
		}

		.modal-body {
			padding-top: 15px;
		}
	}

	.add-receipt-form {
		display: flex;

		section {
			margin-top: 15px;
			margin-bottom: 15px;
		}

		.scanner {
			flex-grow: 4;
		}

		.preview {
			flex-basis: 295px;

			button {
				margin-bottom: 15px;
			}
		}
	}

	.qr-code-reader {
		width: 60%;
		margin-top: 15px;
		margin-bottom: 15px;
	}

	.receipt-manual {
		margin-top: 15px;
		display: flex;
		width: 60%;
		input {
			margin-right: 15px;
		}
	}
}
