$overlayBackgroundColor: rgba(54, 69, 71, 0.64);
$darkColor: #002830;
$headingColor: $darkColor;

$modalRadius: 5px;
$whiteColor: #ffffff;

#modal-root {
	position: relative;
	z-index: 999;
}
.modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	.overlay {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: $overlayBackgroundColor;
	}
}

.modal-dialog {
	position: relative;
	z-index: 10;
	display: flex;
	flex-direction: column;
	width: 90%;
	max-height: 90vh;
	height: 90vh;
	box-shadow: 0 2px 12px 0 black(0.16);
	background: $whiteColor;
	border-radius: $modalRadius;
	.modal-header {
		padding: 10px 20px;
		flex-shrink: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.modal-title {
			font-weight: 700;
			color: $headingColor;
			margin: 0;
		}
		.close {
			cursor: pointer;
			color: #fff;
		}
	}
	.modal-body {
		padding: 0 24px 24px;
		flex-grow: 1;
		overflow: auto;
	}
}
