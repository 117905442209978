.button-login {
	height: 46px;
	display: flex;
	width: 320px;
	border-radius: 5px;
	cursor: pointer;
	span {
		padding-left: 24px;
		align-self: center;
	}

	&.login-with-google {
		background-color: #4285f4;
		color: #fff;
		font-family: "Roboto", sans-serif;
	}
}