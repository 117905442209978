.qr-code-reader {
	.container {
		overflow: hidden;
		position: relative;
		width: 100%;
		padding-top: 100%;
	}
	.hidden {
		display: none;
	}
	.video-preview {
		top: 0;
		left: 0;
		display: block;
		position: absolute;
		overflow: hidden;
		width: 100%;
		height: 100%;
		object-fit: cover;

		&.mirrored {
			transform: scaleX(-1);
		}
	}

	.view-finder {
		top: 0;
		left: 0;
		z-index: 1;
		box-sizing: border-box;
		border: 50px solid rgba(0, 0, 0, 0.3);
		box-shadow: inset 0 0 0 3px rgba(10, 132, 255, 0.3);
		position: absolute;
		width: 100%;
		height: 100%;
	}

	canvas {
		display: none;
	}
}
