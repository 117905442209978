$mainColor: #0a84ff;
.loader {
	width: 256px;
	margin: 0 auto;
	margin-top: 10%;
	position: relative;
	.label {
		position: absolute;
		top: 25%;
		left: 15%;
		z-index: 5000;
		color: $mainColor;
	}

	.lds-ring {
		display: inline-block;
		position: relative;
		width: 256px;
		height: 256px;
		margin: 0 auto;
		vertical-align: middle;
		z-index: 5000;
	}
	.lds-ring div {
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: 128px;
		height: 128px;
		margin: 8px;
		border: 8px solid $mainColor;
		border-radius: 50%;
		animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: $mainColor transparent transparent transparent;
	}
	.lds-ring div:nth-child(1) {
		animation-delay: -0.45s;
	}
	.lds-ring div:nth-child(2) {
		animation-delay: -0.3s;
	}
	.lds-ring div:nth-child(3) {
		animation-delay: -0.15s;
	}
	@keyframes lds-ring {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
}
