.button,
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
	background-color: $mainColor;
	border: 0.1rem solid $mainColor;
	border-radius: 0.4rem;
	color: $initialColor;
	cursor: pointer;
    display: inline-block;
    font-weight: bold;
    //padding: 0.1 1rem;
    padding: 5px 10px;
    /*
    font-size: 1.1rem;
	
	height: 3.8rem;
	letter-spacing: 0.1rem;
	line-height: 3.8rem;
    
    */
	text-align: center;
	text-decoration: none;
	white-space: nowrap;

	&:focus,
	&:hover {
		//background-color: $activeColor;
		//border-color: $activeColor;
		color: $initialColor;
		outline: 0;
	}
	&[disabled] {
		cursor: default;
		opacity: 0.5;

		&:focus,
		&:hover {
			background-color: $mainColor;
			border-color: $mainColor;
		}
	}
	&.button-outline {
		background-color: transparent;
		color: $mainColor;

		&:focus,
		&:hover {
			background-color: transparent;
			border-color: $activeColor;
			color: $activeColor;
		}
		&[disabled] {
			&:focus,
			&:hover {
				border-color: inherit;
				color: $mainColor;
			}
		}
	}
	&.button-clear {
		background-color: transparent;
		border-color: transparent;
		color: $mainColor;

		&:focus,
		&:hover {
			background-color: transparent;
			border-color: transparent;
			color: $activeColor;
		}
		&[disabled] {
			&:focus,
			&:hover {
				color: $mainColor;
			}
		}
	}
}
