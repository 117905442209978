.container {
	max-width: 130rem;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
input[type="week"],
input:not([type]),
textarea,
select {
	-webkit-appearance: none;
	background-color: transparent;
	border: 0.1rem solid #ccc;
	border-radius: 0.4rem;
	box-shadow: none;
	box-sizing: inherit; // Forced to replace inherit values of the normalize.css
	//height: 3.8rem;
	padding: 0.6rem 1rem 0.7rem; // This vertically centers text on FF, ignored by Webkit
	width: 100%;

	&:focus {
		border-color: $mainColor;
		outline: 0;
	}
}
.pill {
	border-radius: 5px;
	background-color: $mainColor;
	padding: 5px 10px;
	color: #fff;
	font-weight: bold;

	&.pill-inverse {
		color: $mainColor;
		border: 1px solid $mainColor;
		background-color: #fff;
	}
}

.empty-state {
	//background-color: rgba(255, 104, 10, 0.1);
	//color: $complementaryColor;

	background-color: rgba(10, 132, 255, 0.09);
	color: $mainColor;
	border: 1px solid $mainColor;
	font-weight: bold;

	padding: 10px;
}

html {
	body {
		margin: 0;
		position: relative;
		min-height: 100vh;
		font-family: "Nunito", sans-serif;
		color: black;
	}
}

.socialIcon {
	height: 4rem;
	margin-left: 1rem;
}

header {
	height: $navbarHeight;
	width: 100%;
	z-index: 1;
	padding-top: 4rem;

	.container {
		height: 100%;
	}

	nav {
		display: flex;
		justify-content: space-between;
	}

	.logo {
		height: 6rem;
	}

	.links {
		height: 6rem;
		padding-top: 2rem;
		.user-name {
			margin-right: 10px;
		}

		.pill {
			border: none;
			padding: none;
			margin-right: 20px;
		}
	}
}

.link {
	cursor: pointer;
}

.container {
	padding-bottom: 0;
}

main {
	padding-top: $navbarHeight;
	// padding-top: $footerHeight;

	h1 {
		font-size: 4rem;
		font-weight: 800;
	}

	.secondText {
		margin-top: -1.5rem;
	}

	.appStore {
		height: 6rem;
		margin-top: 8rem;
	}
}

section {
	&.intro {
		height: 600px;
	}

	.topLeftSection {
		.blue {
			color: $mainColor;
		}
	}

	&.blue {
		background-color: $mainColor;
		color: #fff;
		padding-top: 20rem;
		height: 1000px;

		.blue {
			width: 600px;
			text-align: center;
		}

		.secondText {
			float: right;
			width: 600px;
			text-align: center;
			margin-top: 36rem;
		}

		.iphone-receipt {
			position: absolute;
			right: 0;
			top: -717px;
		}

		.iphone-app {
			position: absolute;
			left: 0;
			top: 215px;
		}
	}

	&.android {
		height: 1000px;
		padding-top: 415px;
		text-align: center;
	}
}

$footerTopPadding: 4.375rem;

$footerHeight: $navbarHeight + $footerTopPadding + 6.125rem;
footer {
	height: $footerHeight;
	background-color: #000;
	color: #fff;
	position: absolute;
	bottom: 0;
	width: 100%;
	padding-top: $footerTopPadding;

	.container {
		height: 100%;
	}

	hr {
		margin: 1rem;
	}

	.contact {
		p {
			margin-top: 2rem;
			margin-bottom: 0px;
		}

		a {
			color: #fff;
		}
	}

	nav {
		a {
			color: #fff;

			&:hover {
				color: #fff;
				text-decoration: underline;
			}
		}
	}

	.links {
		margin-top: $footerTopPadding;
	}

	.company-address {
		color: #333;
	}
}

.inline-block {
	display: inline-block;
}

main {
	min-height: 70rem;
	height: auto;
}
